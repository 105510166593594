// Import bootstrap we need
import 'bootstrap/js/dist/base-component';
import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/tab';
import 'bootstrap/js/dist/tooltip';

import './modules/calendar';

import * as Sentry from "@sentry/browser";
import Alpine from 'alpinejs'

Sentry.init({
    dsn: "https://930da1c5ad8ac0897cd66d40d8eedebc@o43499.ingest.us.sentry.io/4507962795425792",
    integrations: [],
});

/**
 * Checkout form handling
 */
Alpine.data('checkout', () => ({
    currentStep: 1,

    fields: {
        order_first_name: { isValid: true },
        order_last_name: { isValid: true },
        order_email: { isValid: true },
        order_phone: { isValid: true },
        order_address_1: { isValid: true },
        order_state: { isValid: true },
        order_city: { isValid: true },
        order_zipcode: { isValid: true },
        delivery_type: { isValid: true }
    },

    form: null,

    selectedState: '',

    selectedCity: '',

    homeDeliveryAvailable: true,

    init() {
        // Get the form
        this.form = this.$root.querySelector('form')

        // Set the state and city
        this.selectedState = this.form.querySelector('[name="order_state"]').value
        this.selectedCity = this.form.querySelector('[name="order_city"]').value

    },

    validateField(refName) {
        const field = this.$refs[refName]
        this.fields[refName].isValid = field.checkValidity()
    },

    validateRadio(refName) {
        const radios = this.$refs[refName].querySelectorAll('input[type="radio"]');
        this.fields[refName].isvalid = Array.from(radios).some(radio => radio.checked);
    },

    updateDelivery(refName) {
        const field = this.$refs[refName]
        const selected = field.options[field.selectedIndex]

        this.homeDeliveryAvailable = selected.dataset.delivery === '1'

        // Unselect existing delivery option
        const deliverySelected = this.form.querySelector('[name="delivery_type"]:checked')
        if (deliverySelected) {
            deliverySelected.checked = false
        }
    },

    handleNext() {
        // Validate the current visible step
        let isValidStep = true;

        Object.keys(this.fields).forEach(refName => {
            if (this.currentStep === 1 && ['order_first_name','order_last_name','order_email','order_phone'].includes(refName)) {
                this.validateField(refName)
                isValidStep = isValidStep && this.fields[refName].isValid
            } else if (this.currentStep === 2 && ['order_address_1','order_state','order_city','order_zipcode'].includes(refName)) {
                this.validateField(refName)
                isValidStep = isValidStep && this.fields[refName].isValid
            }
        })

        if (isValidStep) {
            this.currentStep++
        }
    },

    submitForm() {
        let isValidStep = true;

        Object.keys(this.fields).forEach(refName => {
            // Validate each field on submission
            if (['delivery_type'].includes(refName)) {
                this.validateRadio(refName)
            } else {
                this.validateField(refName)
            }

            isValidStep = isValidStep && this.fields[refName].isValid
        })

        if (!isValidStep) {
            return false;
        }

        if (fathom) {
            const subTotal = document.getElementById('cart-subtotal')
            if (subTotal) {
                const number = parseFloat(subTotal.innerText.replace('$', ''))
                fathom.trackEvent('checkout completed', { _value: Math.round(number * 100) })
            }
        }
    },

}))

Alpine.start()

// @ts-ignore
import.meta.glob([
    '../images/**',
    '../fonts/**'
]);


